import About from "./components/about";
import FAQ from "./components/faq";
import Footer from "./components/footer";
import Hero from "./components/hero";
import InfiniteSlider from "./components/infiniteSlider";
import YouTube from "./components/youtube";

const App = () => {
  return (
    <>
      <div className="main-wrapper">
        <div className="page-wrapper">
          <Hero />
          <InfiniteSlider />
          <About />
          <FAQ />
          <YouTube />
          <Footer />
        </div>
      </div>
    </>
  );
};

export default App;
