import React from "react";
import preethamImage from "../images/preetham-rounded.webp";
import redHeartIcon from "../images/red-heart.svg";
import CustomButton from "./customButton";

const About: React.FC = () => {
  const features = [
    {
      title: "Infield Experience",
      description:
        "You’ll learn directly from live, real-world interactions—not just theory or videos.",
    },
    {
      title: "Personalized Coaching",
      description:
        "Every step of your journey is tailored to your unique needs and challenges.",
    },
    {
      title: "Proven Results",
      description:
        "My clients don’t just learn skills—they achieve measurable, life-changing results.",
    },
  ];

  return (
    <div className="section-about bg-brand-grey">
      <div className="padding-global">
        <div className="custom-container">
          <div className="py-12">
            <div className="about-component bg-white max-w-[900px] mx-auto px-6 md:px-14 py-8 md:py-[72px] flex flex-col items-center">
              {/* Heading */}
              <h2 className="text-2xl md:text-[40px] font-bold text-center">
                Who's Preetham?
              </h2>

              {/* Image */}
              <div className="my-6 md:my-8">
                <img
                  src={preethamImage}
                  alt="Preetham"
                  className="h-[200px] md:h-[300px] rounded-full"
                />
              </div>

              {/* Description */}
              <p className="text-sm md:text-base text-brand-steel-blue text-center leading-relaxed">
                Hey, my name is Mark Queppet. For over 10 years, I’ve helped
                thousands of men remove low-value, high-stimulation activities
                that are holding them back in life. I’ve dedicated my life to
                helping men unlock their true potential.
              </p>

              {/* Features */}
              <div className="mt-8 w-full">
                {features.map((feature, index) => (
                  <div
                    key={index}
                    className="flex gap-4 md:gap-5 mb-6 last:mb-0 items-start"
                  >
                    <img
                      src={redHeartIcon}
                      alt="Heart"
                      className="h-6 w-6 mt-1"
                    />
                    <div className="flex flex-col gap-1 md:gap-2">
                      <p className="text-lg md:text-xl font-medium">
                        {feature.title}
                      </p>
                      <p className="text-sm md:text-base text-brand-steel-blue">
                        {feature.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>

              {/* Call to Action */}
              <div className="mt-8 text-center">
                <h3 className="text-xl md:text-3xl font-bold">
                  Let’s work together to unlock the confident, charismatic man
                  inside you
                </h3>
                <div className="mt-6">
                  <CustomButton text="Book free 1:1 call" type="primary" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
