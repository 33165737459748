import React from "react";

interface CustomButtonProps {
  text: string; // Prop for button text
  type: "primary" | "secondary"; // Prop for button type
}

const CustomButton: React.FC<CustomButtonProps> = ({ text, type }) => {
  // Conditional styles based on the button type
  const isPrimary = type === "primary";

  return (
    <button
      className={`relative group ${
        isPrimary ? "bg-brand-light-red p-[1px]" : "bg-brand-light-brown p-0"
      }`}
    >
      {/* Main button content */}
      <div
        className={`relative z-10 ${
          isPrimary
            ? "bg-brand-red border-brand-grey"
            : "bg-brand-light-brown border-brand-red"
        } border-[1px] md:py-3 md:px-[18px] py-2 px-2 font-bold text-white text-base md:text-xs`}
      >
        {text}
      </div>
      {/* Decorative background layer */}
      <div
        className="absolute z-0 inset-0 transform translate-x-2 translate-y-2 bg-brand-light-brown transition-transform duration-300 ease-in-out group-hover:translate-x-0 group-hover:translate-y-0"
      ></div>
    </button>
  );
};

export default CustomButton;
