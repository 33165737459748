import React from "react";
import CustomButton from "./customButton";

const Footer: React.FC = () => {
  return (
    <div className="section-footer bg-brand-grey border-t border-b border-brand-red py-6">
      <div className="padding-global">
        <div className="custom-container">
          <p className="text-white text-center text-sm">
            © 2025 thepickupdude. All right reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
