import React from "react";
import CustomButton from "./customButton";

const YouTube: React.FC = () => {
  const videos = [
    "https://www.youtube.com/embed/OK5tQ2j3XPs?si=SI0jyXdddwT5Cph5",
    "https://www.youtube.com/embed/8sFAZXlW2V4?si=Cceq5QLjayJtVSbl",
    "https://www.youtube.com/embed/uc1lUchiaJ0?si=xTWtgCu_1cIg5E1h",
  ];

  return (
    <div className="section-youtube bg-brand-grey overflow-hidden">
      {/* Section Header */}
      <div className="padding-global">
        <div className="custom-container">
          <div className="py-12">
            <p className="text-sm md:text-base text-rosy-coral text-center">
              Free stuffs
            </p>
            <div className="h-2"></div>
            <h2 className="text-center text-white text-2xl md:text-[40px] font-bold">
              Checkout our YouTube Channel
            </h2>
            <div className="h-8"></div>
          </div>
        </div>
      </div>

      {/* Video Slider */}
      <div className="relative overflow-hidden">
        <div className="flex gap-4 md:gap-8 animate-infinite-scroll whitespace-nowrap px-4 md:px-0">
          {[...videos, ...videos, ...videos].map((video, index) => (
            <iframe
              key={index}
              src={video}
              title={`YouTube video player ${index + 1}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              className="rounded-md shadow-md flex-shrink-0 w-[240px] h-[135px] md:w-[360px] md:h-[203px]"
            ></iframe>
          ))}
        </div>
      </div>

      {/* Call-to-Action Button */}
      <div className="h-8"></div>
      <div className="padding-global">
        <div className="custom-container flex justify-center w-full">
          <CustomButton text="Book free 1:1 call" type="primary" />
        </div>
      </div>
      <div className="h-8"></div>
    </div>
  );
};

export default YouTube;
