import React, { useState } from "react";

const FAQ: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const faqs = [
    {
      question: "What if I've never approached a woman before?",
      answer:
        "Untitled has saved us thousands of hours of work. We're able to attract talent and close hires much faster.",
    },
    {
      question: "How is this different from online dating advice?",
      answer:
        "This program provides hands-on coaching and personalized advice tailored to your needs.",
    },
    {
      question: "What is a dating coach for men?",
      answer:
        "A dating coach helps men improve their social skills and confidence to build meaningful connections.",
    },
    {
      question: "What is a dating coach for men?",
      answer:
        "A dating coach helps men improve their social skills and confidence to build meaningful connections.",
    },
  ];

  const toggleFAQ = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="section-faq bg-brand-grey">
      <div className="padding-global">
        <div className="custom-container">
          <div className="py-8 md:py-12">
            {/* Title */}
            <h2 className="text-center text-white text-2xl md:text-[40px] font-bold">
              Frequently Asked Questions
            </h2>
            <div className="mt-6 md:mt-8"></div>

            {/* FAQ List */}
            <div className="max-w-[900px] mx-auto">
              {faqs.map((faq, index) => (
                <div
                  key={index}
                  className="bg-brand-light-brown border border-1 border-brand-red p-4 mb-4"
                >
                  {/* Question */}
                  <div
                    className="flex justify-between gap-2 items-center cursor-pointer"
                    onClick={() => toggleFAQ(index)}
                  >
                    <p className="text-base md:text-lg font-bold text-white">
                      {faq.question}
                    </p>
                    <p className="text-white text-lg md:text-xl font-bold">
                      {activeIndex === index ? "−" : "+"}
                    </p>
                  </div>

                  {/* Answer */}
                  <div
                    className={`transition-all duration-300 ease-in-out overflow-hidden ${
                      activeIndex === index ? "max-h-screen opacity-100 mt-2" : "max-h-0 opacity-0"
                    }`}
                  >
                    <p className="text-sm md:text-base text-white">
                      {faq.answer}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
