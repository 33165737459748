import React from "react";
import logo from "../images/pickup-dude.svg";
import hero_bg from "../images/hero_bg.svg";
import preetham from "../images/preetham.webp";
import CustomButton from "./customButton";
import Navbar from "./navbar";

const Hero: React.FC = () => {
  return (
    <div className="section-hero relative min-h-screen flex flex-col">
      {/* Navbar */}
      <div className="hero-component relative z-10 w-full">
        <Navbar />
        <div className="padding-global">
          <div className="custom-container">
            <div className="py-12">
              {/* Responsive Grid */}
              <div className="hero-inner-wrapper text-white grid grid-cols-1 md:grid-cols-2 gap-12 place-items-center">
                {/* Left Content Block */}
                <div className="hero_content-block text-center md:text-left">
                  <p className="text-base text-rosy-coral">Yo Gamers!</p>
                  <div className="h-2"></div>
                  <h1 className="text-4xl md:text-5xl font-bold">
                    I'm Preetham, your dating coach
                  </h1>
                  <div className="h-6"></div>
                  <p className="text-sm md:text-base leading-relaxed">
                    I help single men with no pickup skills to 'Become A Man
                    With Multiple Options' by teaching them Game through my
                    infields and 90-day training program.
                  </p>
                  <div className="h-12"></div>
                  {/* Button Group */}
                  <div className="button-group flex flex-col md:items-start items-center md:flex-row gap-6">
                    <CustomButton text="Book free 1:1 call" type="primary" />
                    <CustomButton text="Testimonials" type="secondary" />
                  </div>
                </div>
                {/* Right Image Block */}
                <div className="hero_image-block">
                  <img
                    src={preetham}
                    alt="Preetham"
                    className="h-[300px] md:h-[400px] mx-auto"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Background Image */}
      <div className="absolute top-0 left-0 w-full h-full">
        <img
          src={hero_bg}
          alt="Background"
          className="w-full h-full object-cover"
        />
      </div>
    </div>
  );
};

export default Hero;
