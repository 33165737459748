import React from "react";
import WhiteHeart from "../images/white-heart.svg";

const InfiniteSlider: React.FC = () => {
  return (
    <div className="section-slider overflow-hidden bg-brand-grey">
      {/* Outer container for infinite scrolling */}
      <div className="py-12">
        <div className="flex gap-16 animate-infinite-scroll whitespace-nowrap">
          {/* Increased parent gap to gap-16 */}
          {Array(10)
            .fill(null)
            .map((_, idx) => (
              <React.Fragment key={idx}>
                <div className="inline-flex items-center gap-6">
                  {/* Increased gap between text and heart */}
                  <p className="text-red-500 font-bold md:text-7xl text-3xl">DATING</p>
                  <img src={WhiteHeart} alt="Heart" className="h-10 w-10" />
                </div>
                <div className="inline-flex items-center gap-6">
                  <p className="text-red-500 font-bold md:text-7xl text-3xl">PICKUP</p>
                  <img src={WhiteHeart} alt="Heart" className="h-10 w-10" />
                </div>
                <div className="inline-flex items-center gap-6">
                  <p className="text-red-500 font-bold md:text-7xl text-3xl">
                    CONDITIONING
                  </p>
                  <img src={WhiteHeart} alt="Heart" className="h-10 w-10" />
                </div>
              </React.Fragment>
            ))}
        </div>
      </div>
    </div>
  );
};

export default InfiniteSlider;
