import React from "react";
import logo from "../images/pickup-dude.svg"; // Adjust the path if necessary
import CustomButton from "./customButton"; // Adjust the path if necessary

const Navbar: React.FC = () => {
  return (
    <nav className="padding-global text-white py-4">
      <div className="custom-container flex justify-between items-center">
        <img src={logo} alt="Logo" className="md:h-10 h-6 mr-4" />
        <CustomButton text="Book free 1:1 call" type="primary" />
      </div>
    </nav>
  );
};

export default Navbar;
